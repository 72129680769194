import React from "react";
import { IonAccordion, IonAccordionGroup, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonDatetime, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonMenuButton, IonModal, IonNote, IonPage, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar, isPlatform } from "@ionic/react"
import { useEffect, useState } from "react";
import { sparklesOutline, closeOutline, chevronBack, chevronBackOutline, sparkles } from 'ionicons/icons';
import { getYear, getMonth, getDate, getHours, getMinutes, parse, setHours, setMinutes, format, formatISO, set, parseISO } from "date-fns";
import { httpsCallable } from "firebase/functions";
import { functions } from "../config/firebase";
import { motion } from "framer-motion";
import { setYear } from 'date-fns';

import './IdealPartner.css';
import { useHistory } from "react-router";

interface AstroData {
    genderAttraction: string;
    juno?: {
        data: any;
        sign: string | null;
    };
    jupyter?: {
        data: any;
        sign: string | null;
    };
    mars?: {
        data: any;
        sign: string | null;
    };
    rising_sign: {
        data: {
            archetypeKeyword: string;
            name: string;
        } | null;
        sign: string;
    };
    venus?: {
        data: any;
        sign: string | null;
    };
}


const IdealPartner: React.FC = () => {

    const history = useHistory();

    const [name, setName] = useState<string | null | undefined>('Andrea')
    const [birthYear, setBirthYear] = useState<string | null | undefined>(null)
    const [birthDate, setBirthDate] = useState<string>(formatISO(new Date()))
    const [city, setCity] = useState<string | null | undefined>('Calgary')
    const [nation, setNation] = useState<string | null | undefined>('Canada')
    const [genderAttraction, setGenderAttraction] = useState<string | null | undefined>("men")

    const [step, setStep] = useState<number>(1)
    const [showModal, setShowModal] = useState<boolean>(false)

    const [fetching, setFetching] = useState<boolean>(false)
    const [report, setReport] = useState<any>(null)
    const [unlocked, setUnlocked] = useState<boolean>(false)

    const [hours, setHours] = useState('');
    const [minutes, setMinutes] = useState('');
    const [period, setPeriod] = useState('AM');

    const handleYearChange = (year: string | null | undefined) => {
        if (year === undefined || !year) return;
        setBirthYear(year);
        const newDate = setYear(new Date(birthDate), parseInt(year));
        setBirthDate(formatISO(newDate));
    }

    // const handleTimeChange = (e: any) => {
    //     const inputTimestamp = e.detail.value;
    //     console.warn('Input Timestamp: ', inputTimestamp)
    //     const inputDate = parse(inputTimestamp, 'yyyy-MM-dd\'T\'HH:mm:ss', new Date());

    //     const hoursFromInput = new Date(inputDate).getHours();
    //     const minutesFromInput = new Date(inputDate).getMinutes();

    //     const updatedTargetTimestamp = setHours(setMinutes(new Date(birthDate), minutesFromInput), hoursFromInput);
    //     setBirthDate(formatISO(updatedTargetTimestamp));
    //     console.warn('Updated Timestamp: ', updatedTargetTimestamp);
    // }


    const handleDateChange = (e: any) => {
        if (e.detail.value === undefined) return
        setBirthDate(e.detail.value)
    }

    const assertAstroData = (data: AstroData) => {
        // Check if rising sign data is always present
        if (!data.rising_sign.data) {
            throw new Error("Rising sign data is missing.");
        }

        // Check genderAttraction and the corresponding data fields
        if (data.genderAttraction === "men") {
            if (!data.jupyter?.data) {
                setFetching(false)
                throw new Error("jupyter data is missing.");
            }
            if (!data.mars?.data) {
                setFetching(false)
                throw new Error("mars data is missing.");
            }
        } else if (data.genderAttraction === "women") {
            if (!data.venus?.data) {
                setFetching(false)
                throw new Error("venus data is missing.");
            }
            if (!data.juno?.data) {
                setFetching(false)
                throw new Error("juno data is missing.");
            }
        } else {
            setFetching(false)
            throw new Error("Invalid genderAttraction value.");
        }

        return true;
    }

    const generateReport = async () => {
        setFetching(true)
        const date = new Date(birthDate)
        const year = getYear(date)
        const month = getMonth(date) + 1 // 0 based
        const day = getDate(date)
        const hour = getHours(date)
        const minute = getMinutes(date)
        console.log(`Getting chart with: ${name}, ${year}, ${month}, ${day}, ${hour}, ${minute}, ${city}, ${nation}, ${genderAttraction}`)
        if (!name || !year || !month || !day || !hour || !minute || !city || !nation || !genderAttraction) {
            alert('Please complete the form')
            setFetching(false)
            return;
        }
        const results = await httpsCallable(functions, 'ideal_partner_report')({ name, year, month, day, hour, minute, city, nation, genderAttraction })
        console.log('chart: ', results)

        if (assertAstroData(results.data as AstroData)) {
            setReport(results.data)
        }

        setFetching(false)
    }

    const unlock = () => {
        setUnlocked(true)
    }

    const reset = () => {
        setReport(null)
        setUnlocked(false)
        setStep(1)
        setBirthYear(null)
        setBirthDate(formatISO(new Date()))
        setCity(null)
        setNation(null)
        setGenderAttraction(null)
    }

    const goHome = () => {
        history.push('/landing')
    }

    useEffect(() => {
        if (hours && minutes && period && birthDate) {
            let parsedHours = parseInt(hours, 10);
            const parsedMinutes = parseInt(minutes, 10);

            // Adjust hours for PM
            if (period === 'PM' && parsedHours !== 12) {
                parsedHours += 12;
            } else if (period === 'AM' && parsedHours === 12) {
                parsedHours = 0;
            }

            const formattedBirthDate = parseISO(birthDate);
            
            // Create a new Date object with the local date and time
            const combinedDateTime = set(formattedBirthDate, {
                hours: parsedHours,
                minutes: parsedMinutes,
                seconds: 0,
                milliseconds: 0
            });

            // Format the date as an ISO string, but remove the 'Z' at the end to keep it local
            const localISOString = format(combinedDateTime, "yyyy-MM-dd'T'HH:mm:ss");
            setBirthDate(localISOString);
        }
    }, [hours, minutes, period, birthDate]);

    const handleHourChange = (e: any) => {
        setHours(e.target.value);
    };

    const handleMinuteChange = (e: any) => {
        setMinutes(e.target.value);
    };

    const handlePeriodChange = (e: any) => {
        setPeriod(e.detail.value);
    };


    return (
        <IonPage>
            <IonHeader translucent>
                <IonToolbar color="tertiary" style={{ padding: '16px' }}>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    {/* <IonButton className="boxed" slot="end">
                        <IonButton className="boxed" color="light" fill="clear" onClick={login}>
                            <IonLabel>sign in</IonLabel>
                        </IonButton>
                    </IonButtons> */}
                    <IonTitle>
                        <img onClick={goHome} style={{cursor: 'pointer'}} src="/assets/logo_name.png" alt="Mystic Logo" className="navbar-logo" />
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className="clouds-bg">
                <IonLoading backdropDismiss={true} isOpen={fetching} message="Consulting the cosmos" />
                {
                    report ? (
                        <>
                            <div className="">
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '100px' }}>

                        
                                    <IonCard style={{ width: '600px' }}>
                                    <div style={{ margin: '5px' }}>
                                                <IonButton color="tertiary" className="boxed" onClick={reset}>
                                                    <IonIcon icon={closeOutline}></IonIcon>
                                                </IonButton>
                                            </div>
                                            <div>
                                        <IonCardHeader>
                                
                                            <IonCardTitle>Your Ideal Partner Report
                                            </IonCardTitle>
                                            <IonCardSubtitle></IonCardSubtitle>
                                        </IonCardHeader>
                                        </div>
                                        <IonCardContent className={unlocked ? "" : "fade-cover"}>

                                            <IonAccordionGroup multiple={true}>
                                                <IonAccordion value="about-you">
                                                    <IonItem slot="header">
                                                        <IonLabel>About You</IonLabel>
                                                        <IonNote>{report.rising_sign.data.name}</IonNote>
                                                    </IonItem>
                                                    <div slot="content">
                                                        {report.rising_sign.data.signIntro}
                                                    </div>
                                                </IonAccordion>

                                                <IonAccordion value="their-archetype">
                                                    <IonItem slot="header">
                                                        <IonLabel>Their Archetype</IonLabel>
                                                        <IonNote>{report.rising_sign.data.archetypeKeyword}</IonNote>
                                                    </IonItem>
                                                    <div slot="content">
                                                        {report.rising_sign.data.archetypePreview}
                                                    </div>
                                                </IonAccordion>

                                                <IonAccordion value="their-best-traits">
                                                    <IonItem slot="header">
                                                        <IonLabel>Their Best Traits</IonLabel>
                                                    </IonItem>
                                                    <div slot="content">
                                                        {report.rising_sign.data.archetypeEmpowered}
                                                    </div>
                                                </IonAccordion>

                                                <IonAccordion value="their-worst-traits">
                                                    <IonItem slot="header">
                                                        <IonLabel>Their Worst Traits</IonLabel>
                                                    </IonItem>
                                                    <div slot="content">
                                                        {report.rising_sign.data.archetypeShadow}
                                                    </div>
                                                </IonAccordion>

                                                <IonAccordion value="theyll-romance-you">
                                                    <IonItem slot="header">
                                                        <IonLabel>They`ll Romance you by</IonLabel>
                                                    </IonItem>
                                                    <div slot="content">
                                                        {report.rising_sign.data.fifthHousePreview}
                                                    </div>
                                                </IonAccordion>

                                                <IonAccordion value="sexual-connection">
                                                    <IonItem slot="header">
                                                        <IonLabel>Your Sexual Connection is</IonLabel>
                                                    </IonItem>
                                                    <div slot="content">
                                                        {report.rising_sign.data.eighthHousePreview}
                                                    </div>
                                                </IonAccordion>


                                                <IonAccordion value="attracted-to">
                                                    <IonItem slot="header">
                                                        <IonLabel>You`re Deeply Attracted to</IonLabel>
                                                    </IonItem>
                                                    <div slot="content">
                                                        {report.genderAttraction === "men" ? (
                                                            <>{report.mars.data.planetPreview}</>
                                                        ) : (
                                                            <>{report.venus.data.planetPreview}</>
                                                        )}
                                                    </div>
                                                </IonAccordion>

                                                <IonAccordion value="commit-to">
                                                    <IonItem slot="header">
                                                        <IonLabel>The Only Type of Person You`ll Commit to</IonLabel>
                                                    </IonItem>
                                                    <div slot="content">
                                                        {report.genderAttraction === "men" ? (
                                                            <>{report.jupyter.data.planetPreview}</>
                                                        ) : (
                                                            <>{report.juno.data.planetPreview}</>
                                                        )}
                                                    </div>
                                                </IonAccordion>

                                                <IonAccordion value="one-word">
                                                    <IonItem slot="header">
                                                        <IonLabel>Your partner summed up in one word</IonLabel>
                                                    </IonItem>
                                                    <div slot="content">
                                                        {report.rising_sign.data.seventhHousePreview}
                                                    </div>
                                                </IonAccordion>
                                            </IonAccordionGroup>
                                        </IonCardContent>
                                        {!unlocked && (
                                            <div className="ion-padding">
                                                <IonButton onClick={unlock} className="boxed" color="tertiary" expand="block">
                                                    Unlock Full Report $9.99
                                                </IonButton>
                                            </div>
                                        )}

                                    </IonCard>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="birthchart-input-wrapper">

                                <IonCard className="input-card">

                                    <IonCardHeader>
                                        <IonCardTitle>Let`s Find your Ideal Partner
                                        </IonCardTitle>
                                        <IonCardSubtitle>
                                            Welcome to Mystik!
                                        </IonCardSubtitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        <IonGrid>
                                            <IonRow>

                                                {step === 1 && (
                                                    <IonCol size="12" className="wider-card">

                                                        <p style={{ fontSize: '1.2rem', fontWeight: 600 }}>What year were you born?</p>
                                                        <IonItem lines="none" className="input-item">
                                                            <IonInput value={birthYear} onIonChange={(e) => handleYearChange(e.detail.value)} />
                                                        </IonItem>
                                                        <motion.div whileTap={{ scale: 0.9 }}>
                                                            <IonButton className="boxed" color="tertiary" onClick={() => setStep(2)} expand="block">Next</IonButton>
                                                        </motion.div>
                                                    </IonCol>
                                                )}

                                                {step === 2 && (
                                                    <IonCol size="12" className="wider-card">
                                                        <IonButton className="boxed" color="tertiary" onClick={() => setStep(1)}>
                                                            <IonIcon icon={chevronBackOutline} />
                                                            <IonLabel>Back</IonLabel>
                                                        </IonButton>
                                                        <p style={{ marginTop: '20px', fontSize: '1.2rem', fontWeight: 600 }}>What month & day were you born?</p>
                                                        <IonDatetime
                                                            color="tertiary"
                                                            presentation="date"
                                                            preferWheel={isPlatform('desktop') ? false : true}
                                                            value={birthDate}
                                                            onIonChange={(e) => handleDateChange(e)} />
                                                        <motion.div whileTap={{ scale: 0.9 }}>
                                                            <IonButton
                                                                className="boxed"
                                                                color="tertiary"
                                                                expand="block"
                                                                onClick={() => setStep(3)}
                                                            >Next</IonButton>
                                                        </motion.div>
                                                    </IonCol>
                                                )}



                                                {step === 3 && (
                                                    <IonCol size="12" className="wider-card">
                                                        <IonButton className="boxed" color="tertiary" onClick={() => setStep(2)}>
                                                            <IonIcon icon={chevronBackOutline} />
                                                            <IonLabel>Back</IonLabel>
                                                        </IonButton>
                                                        <p style={{ marginTop: '20px', fontSize: '1.2rem', fontWeight: 600 }}>What time were you born?</p>
                                                        <p>Not sure what time you were born? Take a guess, you can always change this later.</p>
                                                        <IonRow>
                                                            <IonCol>
                                                                <IonItem className="input-item">
                                                                    <IonInput type="number" placeholder="HH" value={hours} onIonChange={handleHourChange} />
                                                                </IonItem>
                                                            </IonCol>
                                                            <IonCol>
                                                                <IonItem className="input-item">
                                                                    <IonInput type="number" placeholder="MM" value={minutes ? minutes.padStart(2, '0') : ''} onIonChange={handleMinuteChange} />
                                                                </IonItem>
                                                            </IonCol>
                                                            <IonCol>
                                                                <IonItem className="input-item">
                                                                    <IonSelect value={period} placeholder="Period" onIonChange={handlePeriodChange}>
                                                                        <IonSelectOption value="AM">AM</IonSelectOption>
                                                                        <IonSelectOption value="PM">PM</IonSelectOption>
                                                                    </IonSelect>
                                                                </IonItem>
                                                            </IonCol>
                                                        </IonRow>
                                                        <motion.div whileTap={{ scale: 0.9 }}>
                                                            <IonButton className="boxed" color="tertiary" onClick={() => setStep(4)} expand="block">Next</IonButton>
                                                        </motion.div>
                                                    </IonCol>
                                                )}

                                                {step === 4 && (
                                                    <IonCol size="12" className="wider-card">
                                                        <IonButton className="boxed" color="tertiary" onClick={() => setStep(3)}>
                                                            <IonIcon icon={chevronBackOutline} />
                                                            <IonLabel>Back</IonLabel>
                                                        </IonButton>
                                                        <p style={{ marginTop: '20px', fontSize: '1.2rem', fontWeight: 600 }}>Where were you born?</p>
                                                        <IonItem className="input-item">
                                                            <IonInput value={city} label="City" placeholder="City" onIonChange={(e) => setCity(e.detail.value)} />
                                                        </IonItem>
                                                        <IonItem className="input-item" style={{ marginTop: '10px' }}>
                                                            <IonInput value={nation} label="Country" placeholder="Canada" onIonChange={(e) => setNation(e.detail.value)}></IonInput>
                                                        </IonItem>
                                                        <motion.div whileTap={{ scale: 0.9 }}>
                                                            <IonButton className="boxed" color="tertiary" onClick={() => setStep(5)} expand="block">Next</IonButton>
                                                        </motion.div>
                                                    </IonCol>
                                                )}

                                                {step === 5 && (
                                                    <IonCol size="12" className="wider-card">
                                                        <IonButton className="boxed" color="tertiary" onClick={() => setStep(3)}>
                                                            <IonIcon icon={chevronBackOutline} />
                                                            <IonLabel>Back</IonLabel>
                                                        </IonButton>
                                                        <p style={{ marginTop: '20px', fontSize: '1.2rem', fontWeight: 600 }}>Which gender are you attracted to?</p>
                                                        <IonItem>
                                                            <IonLabel>Gender Attraction</IonLabel>
                                                            <IonSelect onIonChange={(e: any) => setGenderAttraction(e.detail.value)}>
                                                                <IonSelectOption value="women">Women</IonSelectOption>
                                                                <IonSelectOption value="men">Men</IonSelectOption>
                                                            </IonSelect>
                                                        </IonItem>
                                                        <motion.div whileTap={{ scale: 0.9 }}>
                                                            <IonButton className="boxed" color="tertiary" onClick={() => setStep(6)} expand="block">Next</IonButton>
                                                        </motion.div>
                                                    </IonCol>
                                                )}

                                                {step === 6 && (
                                                    <IonCol size="12" className="wider-card">
                                                        <IonButton color="tertiary" className="boxed" onClick={() => setStep(3)}>
                                                            <IonIcon icon={chevronBackOutline} />
                                                            <IonLabel>Back</IonLabel>
                                                        </IonButton>
                                                        <p style={{ marginTop: '30px' }}>Let`s confirm your birth info!</p>
                                                        <IonItem>
                                                            <IonLabel>Date</IonLabel>
                                                            <IonNote>{format(parseISO(birthDate), 'MMM dd yyyy, hh:mm a')}</IonNote>
                                                        </IonItem>
                                                        <IonItem>
                                                            <IonLabel>City</IonLabel>
                                                            <IonNote>{city}</IonNote>
                                                        </IonItem>
                                                        <IonItem>
                                                            <IonLabel>Country</IonLabel>
                                                            <IonNote>{nation}</IonNote>
                                                        </IonItem>

                                                        <IonItem>
                                                            <IonLabel>Attracted to</IonLabel>
                                                            <IonNote style={{ textTransform: 'capitalize' }}>{genderAttraction}</IonNote>
                                                        </IonItem>

                                                        <motion.div whileTap={{ scale: 0.9 }}>
                                                            <IonButton color="tertiary" className="boxed" style={{ marginTop: '20px' }} expand="block" onClick={generateReport}>
                                                                <IonLabel>Find Your Ideal Partner</IonLabel>
                                                                <IonIcon icon={sparkles} slot="start"></IonIcon>
                                                            </IonButton>
                                                        </motion.div>
                                                    </IonCol>
                                                )
                                                }

                                                {/* <IonCol size="6">f
                                                <IonItem>
                                                    <IonInput label="Name" placeholder="Andrea" onIonChange={(e) => setName(e.detail.value)}></IonInput>
                                                </IonItem>
                                                <IonItem>
                                                    <IonInput label="City" placeholder="Vancouver" onIonChange={(e) => setCity(e.detail.value)}></IonInput>
                                                </IonItem>
                                                <IonItem>
                                                    <IonInput label="Country" placeholder="Canada" onIonChange={(e) => setNation(e.detail.value)}></IonInput>
                                                </IonItem>
                                                <IonItem>
                                                    <IonLabel>Gender Attraction</IonLabel>
                                                    <IonSelect onIonChange={(e: any) => setGenderAttraction(e.detail.value)}>
                                                        <IonSelectOption value="women">Women</IonSelectOption>
                                                        <IonSelectOption value="men">Men</IonSelectOption>
                                                    </IonSelect>
                                                </IonItem>

                                            </IonCol>
                                            <IonCol size="6">

                                                <p>Birth Date</p>
                                                <IonDatetime style={{ borderRadius: '10px' }} placeholder="Select Date" value={birthDate} onIonChange={(e) => handleDateChange(e)}>
                                                </IonDatetime>
                                            </IonCol> */}
                                                {/* <IonCol size="12">
                                                <IonButton className="boxed" expand="block" onClick={generateReport}>
                                                    <IonLabel>Ask the Stars</IonLabel>
                                                    <IonIcon icon={sparklesOutline} slot="start"></IonIcon>
                                                </IonButton>
                                            </IonCol> */}
                                            </IonRow>
                                        </IonGrid>
                                    </IonCardContent>
                                </IonCard>
                                <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>


                                </IonModal>
                            </div></>
                    )
                }
            </IonContent>
        </IonPage>
    )

}

export default IdealPartner;
import React from "react";
import { useEffect, useRef, useState } from "react";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
} from '@ionic/react';
import { getFunctions, httpsCallable } from 'firebase/functions';

import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    User,
    UserCredential,
} from "firebase/auth";
import { auth, db } from "../config/firebase";
import useErrorToast from "../hooks/useErrorToast";
import useSuccessToast from "../hooks/useSuccessToast";
import { useHistory, useLocation } from "react-router";
import { doc, getDoc } from 'firebase/firestore';
import { Member } from "../models/member.model";

import './Login.css'

interface LoginProps {
    user: User | null;
    setUser: (user: User | null) => void;
    member: any;
    setMember: (member: Member) => void;
}

const Login: React.FC<LoginProps> = ({ user, setUser, member, setMember }) => {
    const displayError = useErrorToast();
    const displaySuccess = useSuccessToast()
    const history = useHistory();
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [haveAccount, setHaveAccount] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [formPrepopulated, setFormPrepopulated] = useState<boolean>(false);

    const passwordInput = useRef<any>(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const emailParam = queryParams.get('email');
    const firstNameParam = queryParams.get('firstname');
    const lastNameParam = queryParams.get('lastname');

    useEffect(() => {

        if (emailParam && firstNameParam && lastNameParam) {
            setEmail(emailParam!);
            setFirstName(firstNameParam!);
            setLastName(lastNameParam!);
            setFormPrepopulated(true);
            setTimeout(() => {
                if (passwordInput.current) {
                    passwordInput.current.setFocus();
                }
            }, 100)

        }
    }, [emailParam, firstNameParam, lastNameParam])


    const handlePhone = (value: string | null | undefined) => {
        console.log(`input phone value ${value}`)
    }

    const createMember = async (authUser: UserCredential) => {
        if (!email || !password) {
            displayError('Missing info. Please complete the form.')
            return;
        }
        setLoading(true)
        const { uid } = authUser.user;
        const functions = getFunctions();
        const result: any = await httpsCallable(functions, 'member-createMember')({ uid, email })
        if (result.data.error) {
            displayError('Error creating your account. Email support@mystik.com')
            setLoading(false)
        } else {
            getMember(authUser);
        }
    }

    const getMember = async (authUser: UserCredential) => {
        setLoading(true)
        const result = await getDoc(doc(db, 'MEMBERS', authUser.user.uid));
        if (result.exists()) {
            setUser(authUser.user)
            setMember(result.data() as Member);
            history.push('/home');
        } else {
            displayError('Error talking to the internet. ChatGPT has been notified')
        }
        setLoading(false)
    }


    const signIn = () => {
        setLoading(true);
        if (email && password) {
            const authInstance = auth();
            signInWithEmailAndPassword(authInstance, email, password)
                .then((userCredential: UserCredential) => {
                    getMember(userCredential);

                })
                .catch((error) => {
                    console.error(error)
                    displayError(error)
                    setLoading(false)
                });
        } else {
            displayError('Missing email or password')
            setLoading(false)
        }
    }

    const signUp = () => {

        if (!email || !password) {
            displayError('Missing info. Please complete the form.')
            return;
        }

        const authInstance = auth();
        createUserWithEmailAndPassword(authInstance, email, password)
            .then((userCredential: UserCredential) => {
                createMember(userCredential);
            })
            .catch((error) => {
                switch (error) {
                    case 'auth/email-already-in-use':
                        displayError('Email already in use')
                        break;
                    default:
                        displayError(error)
                }
                console.error(error)
                setLoading(false)
            });
    }

    const goBack = () => {
        history.push("/welcome");
    }


    return (
        <IonPage>
            <IonContent className="clouds-bg">


                <div className="welcome-container">
                    <div className="welcome-content-container">
                        <img style={{ marginTop: '40px' }} src="/assets/divider_top.png"></img>
                        <div style={{ width: '100%' }}>
                            <img src="/assets/logo.png"
                                alt="Mystic Logo"
                                onClick={goBack}
                                style={{
                                    width: '100%',
                                    maxWidth: '200px',
                                    margin: '100px auto 0 auto',
                                    display: 'block'
                                }} />
                            <div style={{ textAlign: 'center' }}>
                                <IonItem style={{ margin: '100px auto 0 auto', maxWidth: '400px' }}>
                                    <IonInput
                                        style={{ color: '#FFF8D9' }}
                                        label="phone"
                                        labelPlacement="floating"
                                        value={phone}
                                        onIonChange={(e) => handlePhone(e.detail.value)}
                                        type="email"
                                        required
                                    />
                                </IonItem>
                            </div>
                        </div>
                        <div style={{ marginTop: '40px', minWidth: '200px', maxWidth: '400px' }}>
                            <IonButton
                                style={{width: '100%'}}
                                className="double-border-button"
                                fill="clear"
                                expand="block"
                                onClick={signUp}
                            >join</IonButton>
                        </div>
                        <img
                            style={{ marginTop: '100px' }}
                            //  style={{ position: 'absolute', bottom: '20px' }} 
                            src="/assets/divider_bottom.png"></img>
                    </div>
                </div>


            </IonContent>
        </IonPage>
    )

}

export default Login;
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonButton, IonButtons, IonMenuButton, IonLabel, IonCardContent, IonCard, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonRow, IonChip, IonIcon } from "@ionic/react";
import React from "react";

import './Landing.css'
import { useHistory } from "react-router";
import { sparkles, sparklesOutline } from "ionicons/icons";

const Landing: React.FC = () => {
    const history = useHistory();

    const login = () => {
        // history.push("/login");
        alert('coming soon')

    }

    const soulPlanet = () => {
        history.push("/soul-planet");
    }

    const idealPartner = () => {
        history.push("/ideal-partner");
    }

    return (
        <IonPage>
            <IonHeader translucent>
                <IonToolbar color="tertiary" style={{ padding: '16px' }}>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonButtons slot="end">
                        <IonButton color="light" fill="clear" onClick={login}>
                            <IonLabel>sign in</IonLabel>
                        </IonButton>
                    </IonButtons>
                    <IonTitle>
                        <img src="/assets/logo_name.png" alt="Mystic Logo" className="navbar-logo" />
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className="clouds-bg">

                <IonGrid style={{ maxWidth: '1000px' }}>
                    <IonRow>
                        <IonCol style={{ margin: '60px 30px' }}>
                            <div style={{ textAlign: 'center', color: '#FFF8D9', fontSize: '44px' }}>welcome to Mystic,</div>
                            <div style={{ textAlign: 'center', color: '#FFF8D9', fontSize: '44px' }}>your home for relational astrology</div>
                        </IonCol>
                    </IonRow>
                    <IonRow>

                        <IonCol size="12" sizeMd="6">
                            <IonCard>
                                <div>
                                    <img src="/assets/soul_planet_report.jpeg" style={{ height: '200px', width: '100%', objectFit: 'cover' }} alt="Soul Planet" />
                                </div>
                                <div style={{ textAlign: 'center', margin: '10px auto' }}>
                                    <img style={{ maxWidth: '90%' }} src="/assets/divider_top_dark.png" alt="Divider" />
                                </div>
                                <IonCardHeader>
                                    <IonCardTitle>
                                        <div>
                                            <IonChip color="primary">
                                                <IonIcon icon={sparklesOutline} />
                                                <IonLabel>Start Here !</IonLabel>
                                            </IonChip>
                                        </div>
                                        Soul Planet
                                    </IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    <p>Your soul planet is an exciting new way to get to know yourself! It combines western and vedic astrologies into a complete guide for all of your journeys in love & relationship. It even includes the colors you&apos;ll feel most at home in, gems to support you and much more!</p>
                                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                        <IonButton onClick={soulPlanet} fill="clear" className="double-border-button-dark">
                                            Find your Soul Planet
                                        </IonButton>
                                    </div>
                                </IonCardContent>
                                <div style={{ textAlign: 'center', margin: '10px auto' }}>
                                    <img style={{ maxWidth: '90%' }} src="/assets/divider_bottom_dark.png" alt="Divider" />
                                </div>
                            </IonCard>
                        </IonCol>

                        <IonCol size="12" sizeMd="6">
                            <IonCard>
                                <div>
                                    <img src="/assets/ideal_partner_report.jpeg" style={{ height: '200px', width: '100%', objectFit: 'cover' }} alt="Soul Planet" />
                                </div>
                                <div style={{ textAlign: 'center', margin: '10px auto' }}>
                                    <img style={{ maxWidth: '90%' }} src="/assets/divider_top_dark.png" alt="Divider" />
                                </div>
                                <IonCardHeader>
                                    <IonCardTitle>
                                        <div>
                                            <IonChip color="danger">
                                                <IonIcon icon={sparklesOutline} />
                                                <IonLabel>Just Released !</IonLabel>
                                            </IonChip>
                                        </div>
                                        Ideal Partner Report
                                    </IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    <p>Your ideal partner report contains everything you need to know about the love of your life including their archetype, their positive traits and even their shadows.</p>
                                    <br />
                                    <p>Find your ideal partner, for free.</p>
                                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                        <IonButton onClick={idealPartner} fill="clear" className="double-border-button-dark">
                                            Find your Ideal Partner
                                        </IonButton>
                                    </div>
                                </IonCardContent>

                                <div style={{ textAlign: 'center', margin: '10px auto' }}>
                                    <img style={{ maxWidth: '90%' }} src="/assets/divider_bottom_dark.png" alt="Divider" />
                                </div>
                            </IonCard>
                        </IonCol>

                    </IonRow>
                </IonGrid>



            </IonContent>
        </IonPage>
    )

}

export default Landing;
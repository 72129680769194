import React from "react";
import { IonAccordion, IonAccordionGroup, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonDatetime, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonMenuButton, IonModal, IonNote, IonPage, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar, isPlatform, useIonAlert } from "@ionic/react"
import { useEffect, useState } from "react";
import { sparklesOutline, closeOutline, chevronBack, chevronBackOutline, sparkles } from 'ionicons/icons';
import { getYear, getMonth, getDate, getHours, getMinutes, parse, setHours, setMinutes, format, formatISO, set, parseISO } from "date-fns";
import { httpsCallable } from "firebase/functions";
import { functions, storage } from "../config/firebase";
import { motion } from "framer-motion";
import { setYear } from 'date-fns';

import './SoulPlanet.css';
import { useHistory } from "react-router";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import useSuccessToast from "../hooks/useSuccessToast";

interface AstroData {
    genderAttraction: string;
    juno?: {
        data: any;
        sign: string | null;
    };
    jupyter?: {
        data: any;
        sign: string | null;
    };
    mars?: {
        data: any;
        sign: string | null;
    };
    rising_sign: {
        data: {
            archetypeKeyword: string;
            name: string;
        } | null;
        sign: string;
    };
    venus?: {
        data: any;
        sign: string | null;
    };
}


const SoulPlanet: React.FC = () => {

    const history = useHistory();

    const [presentAlert] = useIonAlert();
    const successToast = useSuccessToast()

    const [name, setName] = useState<string | null | undefined>('Andrea')
    const [birthYear, setBirthYear] = useState<string | null | undefined>(null)
    const [birthDate, setBirthDate] = useState<string>(formatISO(new Date()))

    const [hours, setHours] = useState('');
    const [minutes, setMinutes] = useState('');
    const [period, setPeriod] = useState('AM');

    const [city, setCity] = useState<string | null | undefined>('')
    const [nation, setNation] = useState<string | null | undefined>('')

    const [step, setStep] = useState<number>(1)
    const [showModal, setShowModal] = useState<boolean>(false)

    const [fetching, setFetching] = useState<boolean>(false)
    const [sending, setSending] = useState<boolean>(false)
    const [soulPlanet, setSoulPlanet] = useState<string | null>(null)
    const [unlocked, setUnlocked] = useState<boolean>(false)

    const handleYearChange = (year: string | null | undefined) => {
        if (year === undefined || !year) return;
        setBirthYear(year);
        const newDate = setYear(new Date(birthDate), parseInt(year));
        setBirthDate(formatISO(newDate));
    }

    // const handleTimeChange = (e: any) => {
    //     const inputTimestamp = e.detail.value;
    //     const inputDate = parse(inputTimestamp, 'yyyy-MM-dd\'T\'HH:mm:ss', new Date());

    //     const hoursFromInput = new Date(inputDate).getHours();
    //     const minutesFromInput = new Date(inputDate).getMinutes();

    //     const updatedTargetTimestamp = setHours(setMinutes(new Date(birthDate), minutesFromInput), hoursFromInput);
    //     setBirthDate(formatISO(updatedTargetTimestamp));
    // }

    useEffect(() => {
        console.log(`HOURS: ${hours} :: MINUTES: ${minutes} :: PERIOD: ${period} :: BIRTHDATE: ${birthDate}`)
        if (hours && minutes && period && birthDate) {
            let parsedHours = parseInt(hours, 10);
            const parsedMinutes = parseInt(minutes, 10);

            // Adjust hours for PM
            if (period === 'PM' && parsedHours !== 12) {
                parsedHours += 12;
            } else if (period === 'AM' && parsedHours === 12) {
                parsedHours = 0;
            }

            const formattedBirthDate = parseISO(birthDate);
            
            // Create a new Date object with the local date and time
            const combinedDateTime = set(formattedBirthDate, {
                hours: parsedHours,
                minutes: parsedMinutes,
                seconds: 0,
                milliseconds: 0
            });

            console.log('Combined DateTime:', combinedDateTime);

            // Format the date as an ISO string, but remove the 'Z' at the end to keep it local
            const localISOString = format(combinedDateTime, "yyyy-MM-dd'T'HH:mm:ss");
            setBirthDate(localISOString);
            console.log('Local ISO String:', localISOString);
            console.log('Built DateTime:', format(combinedDateTime, "yyyy-MM-dd'T'HH:mm:ss"));
        } else {
            console.log('Please fill in all inputs');
        }
    }, [hours, minutes, period, birthDate]);

    useEffect(() => {
        console.log('RAW birthDate', birthDate)
        console.log('Parsed birthDate', parseISO(birthDate))
        console.log('Formatted birthDate', format(parseISO(birthDate), 'MMM dd yyyy, hh:mm a'))
    }, [birthDate])

    const handleHourChange = (e: any) => {
        console.log('HOURS', e.target.value)
        setHours(e.target.value);
    };

    const handleMinuteChange = (e: any) => {
        console.log('MINUTES', e.target.value)
        setMinutes(e.target.value);
    };

    const handlePeriodChange = (e: any) => {
        setPeriod(e.detail.value);
    };



    const handleDateChange = (e: any) => {
        if (e.detail.value === undefined) return
        setBirthDate(e.detail.value)
    }

    const assertAstroData = (data: AstroData) => {
        // Check if rising sign data is always present
        if (!data.rising_sign.data) {
            throw new Error("Rising sign data is missing.");
        }

        // Check genderAttraction and the corresponding data fields
        if (data.genderAttraction === "men") {
            if (!data.jupyter?.data) {
                setFetching(false)
                throw new Error("jupyter data is missing.");
            }
            if (!data.mars?.data) {
                setFetching(false)
                throw new Error("mars data is missing.");
            }
        } else if (data.genderAttraction === "women") {
            if (!data.venus?.data) {
                setFetching(false)
                throw new Error("venus data is missing.");
            }
            if (!data.juno?.data) {
                setFetching(false)
                throw new Error("juno data is missing.");
            }
        } else {
            setFetching(false)
            throw new Error("Invalid genderAttraction value.");
        }

        return true;
    }

    const getSoulPlanet = async () => {
        setFetching(true);
        const date = new Date(birthDate);
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // 0 based
        const day = date.getDate();
        const hour = date.getHours();
        const minute = date.getMinutes();
        console.log(`Getting chart with: ${name}, ${year}, ${month}, ${day}, ${hour}, ${minute}, ${city}, ${nation}`);
        if (!name || !year || !month || !day || !hour || !minute || !city || !nation) {
            alert('Please complete the form')
            setFetching(false)
            return;
        }
        const results = await httpsCallable(functions, 'get_soul_planet')({ name, year, month, day, hour, minute, city, nation })
        console.log('soul planet: ', results)

        const { soulPlanet } = results.data as any;

        // TODO handle report gen errors
        // if (assertAstroData(results.data as AstroData)) {
        //     setReport(results.data)
        // }

        // if((results.data as any).soulPlanet) {

        //     setSoulPlanet((results.data as any).soulPlanet)
        // }

        setFetching(false)

        presentAlert({
            header: `Your Soul Planet is ${soulPlanet}`,
            message: `Enter your email to receive your Soul Planet report.`,
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary'
                }, 
                {
                    text: 'Ok',
                    handler: (value) => {
                        const name = value[0]
                        const email = value[1]

                        console.warn('Email', email)
                        console.warn('Name', name)
                        registerEmail(email, name, soulPlanet)
                        // sendEmail(value, soulPlanet)
                    }
                }
            ],
            inputs: [
                {
                    placeholder: 'Name',
                },
                {
                    type: 'email',
                    placeholder: 'Email',
                },
            ]
        })

    }

    const registerEmail = async (email: string, name: string, soulPlanet: string) => {
        setSending(true)
        const results = await httpsCallable(functions, 'member-addToEmailList')({ email, name, soulPlanet })
        console.log('register email results: ', results)
        setSending(false)
        successToast(`Your Soul Planet is ${soulPlanet}! Check your email for your report!`)
        reset()
        goHome()
    }

    const reset = () => {
        setSoulPlanet(null)
        setUnlocked(false)
        setStep(1)
        setBirthYear(null)
        setBirthDate(formatISO(new Date()))
        setCity(null)
        setNation(null)
    }

    const goHome = () => {
        history.push('/landing')
    }

    const downloadSoulPlanet = () => {
        const storage = getStorage();

        // Replace 'path/to/your/pdf.pdf' with the actual path to your PDF file in Firebase Storage
        // const pdfRef = storageRef.child('path/to/your/pdf.pdf');

        getDownloadURL(ref(storage, `${soulPlanet}.pdf`))
            .then((url: any) => {
                console.log('soul planet pdf url: ', url)

                setTimeout(() => {
                    window.open(url, '_top')
                    // window.location.assign(url)
                })

                // `url` is the download URL for 'images/stars.jpg'

                // // This can be downloaded directly:
                // const xhr = new XMLHttpRequest();
                // xhr.responseType = 'blob';
                // xhr.onload = (event) => {
                //     const blob = xhr.response;
                // };
                // xhr.open('GET', url);
                // xhr.send();

                // Or inserted into an <img> element
                // const img = document.getElementById('myimg');
                // img!.setAttribute('src', url);
            })
            .catch((error) => {
                // Handle any errors
            });
    };


    return (
        <IonPage>
            <IonHeader translucent>
                <IonToolbar color="tertiary" style={{ padding: '16px' }}>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    {/* <IonButton className="boxed" slot="end">
                        <IonButton className="boxed" color="light" fill="clear" onClick={login}>
                            <IonLabel>sign in</IonLabel>
                        </IonButton>
                    </IonButtons> */}
                    <IonTitle>
                        <img onClick={goHome} style={{ cursor: 'pointer' }} src="/assets/logo_name.png" alt="Mystic Logo" className="navbar-logo" />
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className="clouds-bg">
                <IonLoading backdropDismiss={true} isOpen={fetching} message="Consulting the cosmos" />
                <IonLoading backdropDismiss={true} isOpen={sending} message="Communicating with starlight" />
                {
                    soulPlanet ? (
                        <>
                            <div className="">
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '100px' }}>


                                    <IonCard style={{ width: '600px' }}>
                                        <div style={{ margin: '5px' }}>
                                            <IonButton color="tertiary" className="boxed" onClick={reset}>
                                                <IonIcon icon={closeOutline}></IonIcon>
                                            </IonButton>
                                        </div>
                                        <div>
                                            <IonCardHeader>
                                                <IonCardTitle>Your Soul Planet
                                                </IonCardTitle>
                                                {/* <IonCardSubtitle></IonCardSubtitle> */}
                                            </IonCardHeader>
                                        </div>
                                        <IonCardContent>
                                            <div>
                                                Hey Friend! Your Soul Planet is <span style={{ fontWeight: 600 }}>{soulPlanet}</span>
                                            </div>
                                            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                                <IonButton onClick={downloadSoulPlanet} fill="clear" className="double-border-button-dark">
                                                    Download Report
                                                </IonButton>
                                            </div>

                                        </IonCardContent>

                                    </IonCard>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="birthchart-input-wrapper">

                                <IonCard className="input-card">

                                    <IonCardHeader>
                                        <IonCardTitle>Let`s Find your Soul Planet
                                        </IonCardTitle>
                                        <IonCardSubtitle>
                                            Welcome to Mystik!
                                        </IonCardSubtitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        <IonGrid>
                                            <IonRow>

                                                {step === 1 && (
                                                    <IonCol size="12" className="wider-card">

                                                        <p style={{ fontSize: '1.2rem', fontWeight: 600 }}>What year were you born?</p>
                                                        <IonItem lines="none" className="input-item">
                                                            <IonInput value={birthYear} onIonChange={(e) => handleYearChange(e.detail.value)} />
                                                        </IonItem>
                                                        <motion.div whileTap={{ scale: 0.9 }}>
                                                            <IonButton className="boxed" color="tertiary" onClick={() => setStep(2)} expand="block">Next</IonButton>
                                                        </motion.div>
                                                    </IonCol>
                                                )}

                                                {step === 2 && (
                                                    <IonCol size="12" className="wider-card">
                                                        <IonButton className="boxed" color="tertiary" onClick={() => setStep(1)}>
                                                            <IonIcon icon={chevronBackOutline} />
                                                            <IonLabel>Back</IonLabel>
                                                        </IonButton>
                                                        <p style={{ marginTop: '20px', fontSize: '1.2rem', fontWeight: 600 }}>What month & day were you born?</p>
                                                        {/* <p>{birthDate}</p> */}
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <IonDatetime
                                                                color="tertiary"
                                                                presentation="date"
                                                                preferWheel={isPlatform('desktop') ? false : true}
                                                                value={birthDate}
                                                                onIonChange={(e) => handleDateChange(e)} />
                                                        </div>
                                                        <motion.div whileTap={{ scale: 0.9 }}>
                                                            <IonButton
                                                                className="boxed"
                                                                color="tertiary"
                                                                expand="block"
                                                                onClick={() => setStep(3)}
                                                            >Next</IonButton>
                                                        </motion.div>
                                                    </IonCol>
                                                )}



                                                {step === 3 && (
                                                    <IonCol size="12" className="wider-card">
                                                        <IonButton className="boxed" color="tertiary" onClick={() => setStep(2)}>
                                                            <IonIcon icon={chevronBackOutline} />
                                                            <IonLabel>Back</IonLabel>
                                                        </IonButton>
                                                        <p style={{ marginTop: '20px', fontSize: '1.2rem', fontWeight: 600 }}>What time were you born?</p>
                                                        <p>Not sure what time you were born? Take a guess, you can always change this later.</p>
                                                        <IonRow>
                                                            <IonCol>
                                                                <IonItem className="input-item">
                                                                    <IonInput type="number" placeholder="HH" value={hours} onIonChange={handleHourChange} />
                                                                </IonItem>
                                                            </IonCol>
                                                            <IonCol>
                                                                <IonItem className="input-item">
                                                                    <IonInput type="number" placeholder="MM" value={minutes ? minutes.padStart(2, '0') : ''} onIonChange={handleMinuteChange} />
                                                                </IonItem>
                                                            </IonCol>
                                                            <IonCol>
                                                                <IonItem className="input-item">
                                                                    <IonSelect value={period} placeholder="Period" onIonChange={handlePeriodChange}>
                                                                        <IonSelectOption value="AM">AM</IonSelectOption>
                                                                        <IonSelectOption value="PM">PM</IonSelectOption>
                                                                    </IonSelect>
                                                                </IonItem>
                                                            </IonCol>
                                                        </IonRow>
                                                        {/* <IonDatetime color="tertiary" style={{ margin: '0 auto' }} value={birthDate} presentation="time" onIonChange={(e) => handleTimeChange(e)} /> */}
                                                        <motion.div whileTap={{ scale: 0.9 }}>
                                                            <IonButton className="boxed" color="tertiary" onClick={() => setStep(4)} expand="block">Next</IonButton>
                                                        </motion.div>
                                                    </IonCol>
                                                )}

                                                {step === 4 && (
                                                    <IonCol size="12" className="wider-card">
                                                        <IonButton className="boxed" color="tertiary" onClick={() => setStep(3)}>
                                                            <IonIcon icon={chevronBackOutline} />
                                                            <IonLabel>Back</IonLabel>
                                                        </IonButton>
                                                        <p style={{ marginTop: '20px', fontSize: '1.2rem', fontWeight: 600 }}>Where were you born?</p>
                                                        <IonItem className="input-item">
                                                            <IonInput value={city} label="City" placeholder="City" onIonChange={(e) => setCity(e.detail.value)} />
                                                        </IonItem>
                                                        <IonItem className="input-item" style={{ marginTop: '10px' }}>
                                                            <IonInput value={nation} label="Country" placeholder="Canada" onIonChange={(e) => setNation(e.detail.value)}></IonInput>
                                                        </IonItem>
                                                        <motion.div whileTap={{ scale: 0.9 }}>
                                                            <IonButton className="boxed" color="tertiary" onClick={() => setStep(5)} expand="block">Next</IonButton>
                                                        </motion.div>
                                                    </IonCol>
                                                )}

                                                {step === 5 && (
                                                    <IonCol size="12" className="wider-card">
                                                        <IonButton color="tertiary" className="boxed" onClick={() => setStep(3)}>
                                                            <IonIcon icon={chevronBackOutline} />
                                                            <IonLabel>Back</IonLabel>
                                                        </IonButton>
                                                        <p style={{ marginTop: '30px' }}>Let`s confirm your birth info!</p>
                                                        <IonItem>
                                                            <IonLabel>Date</IonLabel>
                                                            <IonNote>{format(parseISO(birthDate), 'MMM dd yyyy, hh:mm a')}</IonNote>
                                                        </IonItem>
                                                        <IonItem>
                                                            <IonLabel>City</IonLabel>
                                                            <IonNote>{city}</IonNote>
                                                        </IonItem>
                                                        <IonItem>
                                                            <IonLabel>Country</IonLabel>
                                                            <IonNote>{nation}</IonNote>
                                                        </IonItem>

                                                        <motion.div whileTap={{ scale: 0.9 }}>
                                                            <IonButton
                                                                color="tertiary"
                                                                className="boxed"
                                                                style={{ marginTop: '20px' }}
                                                                expand="block"
                                                                onClick={getSoulPlanet}>
                                                                <IonLabel>Find Your Soul Planet</IonLabel>
                                                                <IonIcon icon={sparkles} slot="start"></IonIcon>
                                                            </IonButton>
                                                        </motion.div>
                                                    </IonCol>
                                                )
                                                }


                                            </IonRow>
                                        </IonGrid>
                                    </IonCardContent>
                                </IonCard>
                                <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>


                                </IonModal>
                            </div></>
                    )
                }
            </IonContent>
        </IonPage>
    )

}

export default SoulPlanet;
import React from 'react';
import {
    IonChip,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
} from '@ionic/react';

import { useHistory, useLocation } from 'react-router-dom';
import { archiveOutline, archiveSharp, bookmarkOutline, heartOutline, heartSharp, logOut, logOutOutline, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, planetOutline, planetSharp, sparklesOutline, sparklesSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import './Menu.css';

interface AppPage {
    url: string;
    iosIcon: string;
    mdIcon: string;
    title: string;
}

const appPages: AppPage[] = [
    {
        title: 'Soul Planet',
        url: '/soul-planet',
        iosIcon: planetOutline,
        mdIcon: planetSharp
    },
    {
        title: 'Ideal Partner Report',
        url: '/ideal-partner',
        iosIcon: sparklesOutline,
        mdIcon: sparklesSharp
    }
];



const Menu: React.FC = () => {
    const location = useLocation();
    const history = useHistory();

    const landing = () => {
        history.push("/landing");
    }

    return (
        <IonMenu contentId="main" type="overlay">
            <IonContent className="flowers-bg">
                <div onClick={landing} style={{ textAlign: 'center' }}>
                    <IonMenuToggle>
                        <img src="/assets/logo_2x.png" alt="Mystic Logo" style={{ margin: '10px auto', maxWidth: '80%' }} />
                    </IonMenuToggle>
                </div>
                {/* {appPages.map((appPage, index) => {
                    return (
                   
                })} */}

                <IonMenuToggle autoHide={false}>
                    <IonItem style={{ '--background': 'transparent' }}
                        className={location.pathname === '/soul-planet' ? 'selected' : ''}
                        routerLink="/soul-planet"
                        routerDirection="none"
                        lines="none"
                        detail={false}>
                        <IonIcon color="light" aria-hidden="true" slot="start" icon={planetOutline} />
                        <IonLabel color="light">Soul Planet</IonLabel>
                    </IonItem>
                </IonMenuToggle>

                {/* <IonMenuToggle autoHide={false}> */}
                    <IonItem
                        style={{ '--background': 'transparent' }}
                        className={location.pathname === '/ideal-partner' ? 'selected' : ''}
                        // routerLink="/soul-planet"
                        routerDirection="none"
                        lines="none"
                        detail={false}>
                        <IonIcon color="light" aria-hidden="true" slot="start" icon={sparklesOutline} />
                        <IonLabel color="light">Ideal Partner</IonLabel>
                        <IonChip color="light" slot="end">
                            {/* <IonIcon icon={sparklesOutline} /> */}
                            <IonLabel>Coming Soon</IonLabel>
                        </IonChip>
                    </IonItem>
                {/* </IonMenuToggle> */}




                {/* <IonItem style={{ '--background': 'transparent', marginTop: '200px' }}>
                    <IonIcon color="light" slot="start" icon={logOutOutline} />
                    <IonLabel color="light">Logout</IonLabel>
                </IonItem> */}
            </IonContent>
        </IonMenu>
    );
};

export default Menu;

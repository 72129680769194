import React from 'react';
import { BrowserRouter, Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  setupIonicReact,
  isPlatform,
  IonPage,
  IonContent,
  IonButton,
  IonSplitPane,
  IonRouterOutlet,
} from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import './App.css'

import MystikApp from './MystikApp';
import { ErrorBoundary } from "react-error-boundary";
import { addDoc, collection } from 'firebase/firestore';

import Login from "./pages/Login";
import { useEffect, useState } from "react";
import { User } from "firebase/auth";
import { auth, db } from "./config/firebase";
import { doc, getDoc } from "firebase/firestore";
import Welcome from "./pages/Welcome";
import Landing from "./pages/Landing";
import Menu from "./components/Menu";
import SoulPlanet from "./pages/SoulPlanet";
import IdealPartner from "./pages/IdealPartner";
import { IonReactRouter } from '@ionic/react-router';

setupIonicReact({
  mode: isPlatform('android') ? 'md' : 'ios',
});

const Fallback = (props: any) => {
  const { error, resetErrorBoundary } = props;
  return (
    <IonPage>
      <IonContent className="ion-padding" style={{ textAlign: 'center' }}>
        <p>Something went wrong...</p>
        <p>Please try again or email <a style={{ color: 'var(--ion-color-dark' }} href="mailto:support@risingwoman.com">support</a></p>
        <p>{error.message}</p>

        <IonButton expand="block" color="dark" shape="round" onClick={resetErrorBoundary} > Try Again </IonButton>
      </IonContent>
    </IonPage>
  );
}

const logError = async (error: Error, info: any) => {

  const { message, stack } = error;

  console.log(error, info);
  const docRef = await addDoc(collection(db, 'ERRORS'), {
    message,
    stack,
    info: info ? info.componentStack : null
  })
  console.log('Error logged: ', docRef.id)
}


const App: React.FC = () => {

  const [user, setUser] = useState<User | null>(null);
  const [member, setMember] = useState<any>(null);

  useEffect(() => {
    auth().onAuthStateChanged(async (user) => {
      if (user) {
        const result = await getDoc(doc(db, 'MEMBERS', user.uid));
        if (result.exists()) {
          console.warn('Member at app level: ', result.data())
          setMember(result.data());
          setUser(user);
        }
      } else {
        setUser(null);
        setMember(null)
      }
    });
  }, []);

  return (
    <IonApp>
      <IonReactRouter>
        <ErrorBoundary FallbackComponent={Fallback} onError={logError}>
          <IonSplitPane when={false} contentId="main">
            <Menu />
            <IonRouterOutlet id="main">
              <Route exact path="/login">
                <Login
                  user={user}
                  setUser={setUser}
                  member={member}
                  setMember={setMember} />
              </Route>
              <Route exact path="/welcome">
                <Welcome />
              </Route>
              <Route exact path="/landing">
                <Landing />
              </Route>
              <Route exact path="/soul-planet">
                <SoulPlanet />
              </Route>
              <Route exact path="/ideal-partner">
                <IdealPartner />
              </Route>
              <Redirect to="/welcome" />
            </IonRouterOutlet>
          </IonSplitPane>
        </ErrorBoundary>
      </IonReactRouter>

    </IonApp>
  )
};

export default App;

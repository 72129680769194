
/**
 * this just needs to be imported somewhere
 * then it'll be initialized globally and accesible throughout the app
 */
import { getApp, initializeApp } from 'firebase/app';
import { Auth, getAuth, indexedDBLocalPersistence, initializeAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';
import { Capacitor } from '@capacitor/core';

console.warn('Env: ', process.env.REACT_APP_ENV);

const config =
  process.env.REACT_APP_ENV === 'production'
    ? {
        apiKey: "AIzaSyBdV_-lPvDMlAWg8_COQ10FA4yXy6p2jWU",
        authDomain: "mystik-playground.firebaseapp.com",
        projectId: "mystik-playground",
        storageBucket: "mystik-playground.appspot.com",
        messagingSenderId: "455628632295",
        appId: "1:455628632295:web:5882ce066466b138ca3242",
        measurementId: "G-1F4QZBT1PM"
      }
    :{
        apiKey: "AIzaSyBdV_-lPvDMlAWg8_COQ10FA4yXy6p2jWU",
        authDomain: "mystik-playground.firebaseapp.com",
        projectId: "mystik-playground",
        storageBucket: "mystik-playground.appspot.com",
        messagingSenderId: "455628632295",
        appId: "1:455628632295:web:5882ce066466b138ca3242",
        measurementId: "G-1F4QZBT1PM"
      };

console.warn('Firebase config: ', config)

const app = initializeApp(config);

export const functions = getFunctions(app);
export const auth = (): Auth => {
  if (Capacitor.isNativePlatform()) {
    return initializeAuth(getApp(), {
      persistence: indexedDBLocalPersistence
    });
  } else {
    // TODO figure out if we need to setPersistence(browserLocalPersistence);
    return getAuth(app);
  }
};
export const db = getFirestore(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);

// connectFunctionsEmulator(functions, "localhost", 5001);

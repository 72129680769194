import React from "react";
import { IonButton, IonContent, IonHeader, IonPage, IonRouterLink, IonTitle, IonToolbar } from "@ionic/react";
import { useHistory } from "react-router";
import './Welcome.css'

const Welcome: React.FC = () => {

    const history = useHistory();

    const login = () => {
        // event.preventDefault();
        history.push("/landing");
    }

    return (
        <IonPage >
            <IonContent className="clouds-bg">
                <div className="welcome-container">
                    <div className="welcome-content-container">
                        <img src="/assets/divider_top.png" alt="Divider" />
                        <img src="/assets/logo_2x.png" alt="Mystic Logo" className="welcome-logo" />
                        <div style={{color: '#FFF8D9', marginTop: '20px', fontSize: '24px'}}>welcome to relational astrology</div>
                        <IonButton fill="clear" className="welcome-button double-border-button" onClick={login}>
                            enter the cosmos
                        </IonButton>
                        <img src="/assets/divider_bottom.png" alt="Divider" className="welcome-divider-bottom" />
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )

}
export default Welcome;